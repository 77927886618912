<div class="container">
  <app-nav></app-nav>
  <div class="containerSlider">
    <div class="carousel" #carousel>
      <div
        class="item"
        *ngFor="let item of imagesArray; let i = index"
        [ngStyle]="{ 'background-image': 'url(' + item.url + ')' }"
      ></div>
    </div>
  </div>
  <div class="services-container">
    <div class="connector1"></div>
    <div class="connector2"></div>
    <div class="service-container marving">
      <div class="service">
        <span class="tooltip" [innerHTML]="tooltip.marv.desc"></span>
        <div class="box-shadow"></div>
        <div class="service-card">
          <img
            class="service-card-image"
            src="assets/icons/traveler.svg"
            alt=""
          />
          <div class="service-card-title">
            Marveler
            <span class="serivce-subtitle">
              Find a local expert<br />
              wherever you<br />
              travel
            </span>
          </div>
          <div class="action-btn">
            <button
              class="primary-btn primary-btn--primary"
              (click)="scrollToMarv()"
            >
              Start Marving
            </button>
          </div>
        </div>
      </div>
    </div>
    <img
      class="moving-image"
      src="assets/images/final favicon.png"
      alt="Moving Image"
    />
    <div class="service-container">
      <div class="service counseling">
        <span class="tooltip-left" [innerHTML]="tooltip.counselor.desc"></span>
        <div class="box-shadow"></div>
        <div class="service-card">
          <img class="service-card-image" src="assets/icons/hike.svg" alt="" />
          <div class="service-card-title">
            Counselor
            <span class="serivce-subtitle">
              showcase your<br />
              expertise on your<br />
              city
            </span>
          </div>
        </div>
        <div class="action-btn">
          <button
            class="primary-btn primary-btn--secondary"
            (click)="scrollToCounselor()"
          >
            Start Counseling
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- <div class="join-now-container">
    <div class="join-now marv">
      <div>
        Pre-Register and Get
        <span style="font-size: 36px; text-shadow: 0 0 5px white"
          ><br />30% off<br
        /></span>
        on your first trip.
      </div>
      <div class="button" [routerLink]="['/preregister']">Join Now</div>
      <br />
      <div>August Launch! Mark Your Calendars!</div>
    </div>
    <div class="join-now">
      <div>
        Pre-Register and Get
        <span style="font-size: 36px; text-shadow: 0 0 5px white"
          ><br />30% off<br
        /></span>
        on your first trip.
      </div>
      <div class="button" [routerLink]="['/preregister']">Join Now</div>
      <br />
      <div>August Launch! Mark Your Calendars!</div>
    </div>
  </div> -->
  <div class="how-container">
    <!-- <div class="heading">
      <div>How Marving Works</div>
    </div>
    <div class="cards-container">
      <div class="card">
        <img src="assets/icons/settings.svg" alt="" class="card-image" />
        <div class="card-text">Register and customize your profile</div>
      </div>
      <img src="assets/icons/next-right.svg" alt="" class="next" />
      <div class="card">
        <img src="assets/icons/puzzle.svg" alt="" class="card-image" />
        <div class="card-text">
          Search for a destination and get instantly matched
        </div>
      </div>
      <img src="assets/icons/next-right.svg" alt="" class="next" />
      <div class="card">
        <img src="assets/icons/calender.svg" alt="" class="card-image" />
        <div class="card-text">
          Book the service(s) you wanted based on their availability
        </div>
      </div>
      <img src="assets/icons/next-right.svg" alt="" class="next" />
      <div class="card">
        <img
          src="assets/icons/meeting-discussion.svg"
          alt=""
          class="card-image"
        />
        <div class="card-text">
          Have an online consultation and/or meet them in person
        </div>
      </div>
      <img src="assets/icons/next-right.svg" alt="" class="next" />
      <div class="card">
        <img src="assets/icons/network.svg" alt="" class="card-image" />
        <div class="card-text">
          Give a feedback and add them to your global network
        </div>
      </div>
    </div> -->
    <div class="join-now marv">
      <div>
        Pre-Register and Get
        <span style="font-size: 36px; text-shadow: 0 0 5px white"
          ><br />30% off<br
        /></span>
        on your first trip.
      </div>
      <div class="button" [routerLink]="['/new-marveler']">JOIN NOW</div>
      <br />
      <div>August Launch! Mark Your Calendars!</div>
    </div>
  </div>
  <!-- <div class="what-container">
    <div class="heading">
      <div>What we offer for Marveler</div>
    </div>
    <div class="services-container">
      <div class="service-card">
        <img
          class="service-card-image"
          src="assets/icons/video-call.svg"
          alt=""
        />
        <div class="heading">Ask the Counselor</div>
        <div class="service-card-details">
          Chat with a Counselor or meet them on a video call to receive
          personalized recommendations, itineraries, culture impressions, and
          even some useful expressions in their language.
        </div>
        <button
          class="primary-btn primary-btn--primary"
          [routerLink]="['/services']"
          routerLinkActive="router-link-active"
        >
          Read more
        </button>
      </div>

      <div class="service-card">
        <img class="service-card-image" src="assets/icons/meet.svg" alt="" />
        <div class="heading">Meet the Counselor</div>
        <div class="service-card-details">
          Meet with a Counselor in person to go on a guided tour carefully
          planned together, so it meets your preferences, needs, and interests.
          and unlock an authentic travel experience.
        </div>
        <button
          class="primary-btn primary-btn--primary"
          [routerLink]="['/services']"
        >
          Read more
        </button>
      </div>
    </div>
  </div>
  <div class="benefits-container d-none">
    <div class="heading">
      <img src="assets/icons/message-square-list-svgrepo-com.svg" alt="" />
      <div>How it works</div>
    </div>
    <div class="benefits-container">

      <div class="benefits-grid">
        <div class="marveler">
          <div class="icon">
            <img src="assets/icons/airplane.svg" alt="" />
          </div>
          <div class="marveler-container">
            <div class="marveler-info">
              <h3>What can you do as a Marveler?</h3>

              <ul>
                <li>Create your profile with your interests and passions</li>
                <li>
                  Search for travel services or guides that match your
                  preferences
                </li>
                <li>
                  Book and enjoy your travel experience, and share your feedback
                  and stories with the MARV community
                </li>
              </ul>
            </div>
            <div class="marveler-benefits">
              <h3>What are your benefits?</h3>

              <ul>
                <li>Customized Experience</li>
                <li>Affordability</li>
                <li>On-demand service</li>
                <li>Networking</li>
                <li>Rating system</li>
                <li>Safety</li>
              </ul>
            </div>
          </div>
          <div class="action-btn">
            <button class="primary-btn primary-btn--white">
              Join Marvling
            </button>
          </div>
        </div>
        <div class="marveler">
          <div class="icon">
            <img src="assets/icons/map-small.svg" alt="" />
          </div>
          <div class="marveler-container">
            <div class="marveler-info">
              <h3>What can you do as a Counselor?</h3>

              <ul>
                <li>
                  Create your profile, showcasing your abilities, experience,
                  and authenticity
                </li>
                <li>Create your travel service and offer it to Marvelers</li>
                <li>Share your feedback and stories with the MARV community</li>
              </ul>
            </div>
            <div class="marveler-benefits">
              <h3>What are your benefits?</h3>

              <ul>
                <li>Income generation</li>
                <li>Flexible & predictable working hour</li>
                <li>No asset consumption</li>
                <li>Rewards Program</li>
                <li>Rating system</li>
                <li>Networking</li>
                <li>Carrier Progression</li>
              </ul>
            </div>
          </div>
          <div class="action-btn">
            <button class="primary-btn primary-btn--white">
              Join Counseling
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="benefits-container">
    <div class="heading">
      <img src="assets/icons/map-svgrepo-com.svg" alt="" />
      <div>What we offer?</div>
    </div>
    <div class="services-container">
      <div class="service-card">
        <img
          class="service-card-image"
          src="assets/icons/video-call.svg"
          alt=""
        />
        <div class="heading">Ask the Counselor</div>
        <div class="service-card-details">
          Chat with a Counselor or meet them on a video call to receive
          personalized recommendations, itineraries, culture impressions, and
          even some useful expressions in their language.
        </div>
        <button
          class="primary-btn primary-btn--primary"
          [routerLink]="['/coming-soon']"
          routerLinkActive="router-link-active"
        >
          Read more
        </button>
      </div>

      <div class="service-card">
        <img class="service-card-image" src="assets/icons/meet.svg" alt="" />
        <div class="heading">Meet the Counselor</div>
        <div class="service-card-details">
          Meet with a Counselor in person to go on a guided tour carefully
          planned together, so it meets your preferences, needs, and interests.
          and unlock an authentic travel experience.
        </div>
        <button
          class="primary-btn primary-btn--primary"
          [routerLink]="['/coming-soon']"
        >
          Read more
        </button>
      </div>
    </div>
  </div> -->
  <!-- 
    <app-how-counselor-works></app-how-counselor-works>
    <app-what-counselor-offers></app-what-counselor-offers> -->
    <app-milo></app-milo>
    <app-footer></app-footer>
</div>

import { CommonModule } from '@angular/common';
import { Component, ElementRef, ViewChild } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { RouterModule } from '@angular/router';
import { MapComponent } from '../../components/map/map.component';
import { NavComponent } from '../../components/nav/nav.component';
import { FooterComponent } from '../../components/footer/footer.component';
import { MiloComponent } from '../../components/milo/milo.component';

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [
    CommonModule,
    MapComponent,
    MatIconModule,
    MatMenuModule,
    NavComponent,
    RouterModule,
    FooterComponent,
    MiloComponent
  ],
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss'
})

export class HomeComponent {
  currdeg: number = 0;
  currentStep = 60;
  imagesArray = [{ url: "assets/images/1.webp", title: "X" },
  { url: "assets/images/2.webp", title: "X" },
  { url: "assets/images/3.webp", title: "X" },
  { url: "assets/images/4.webp", title: "X" },
  { url: "assets/images/5.webp", title: "X" },
  { url: "assets/images/6.webp", title: "X" }
  ]
  tooltip = {
    marv: {
      desc: `- A Marveler is a traveler interested in authentic experiences crafted by local experts<br>
      - Register as a Marveler and we’ll match you with a Counselor (local buddy/expert) who shares your passions and interests<br>
      - Reach out to your Counselor for a simple consultation or meet them in person<br>
      - Enhance your traveling experiences by saving time searching for hidden gems and culture immersion<br>
      - With our SOS-M community you can get help fast directly from locals close to you for any unforeseen situation<br>
      `,
    },
    counselor: {
      desc: `
      - A Counselor is a local buddy/expert who crafts personalized experiences for their perfectly matched Marvelers (travelers)<br>
      - You can register as a Counselor whether you are an experienced guide or simply a local with deep knowledge and passion for your city<br>
      - We support you with courses and tips & tricks to enhance your skills and better promote your services, and a dedicated career path to grow your expertise and become a trusted local Pro Counselor<br>
      - With our SOS-C community you have other Counselors close to you, ready to help with any unforeseen situation<br>
      - In your dashboard, you can manage bookings, monitor your progress, and track your income<br>
      `,
    },
  };

  @ViewChild('carousel') carousel: ElementRef<HTMLElement>
  ngAfterViewInit() {
    const items: NodeList = this.carousel.nativeElement.querySelectorAll('.item');
    this.currentStep = 360 / items.length;
    items.forEach((element: Node, index: number) => {
      const htmlElement = element as HTMLElement;
      const rotation = this.currentStep * index;
      htmlElement.style.transform = `rotateY(${rotation}deg) translateZ(430px)`;
    });
  }
  scrollToCounselor() {
    const screenWidth = window.innerWidth;
    const scrollPosition = screenWidth >= 768 ? 2600 : 6000;
    window.scrollTo({
      top: scrollPosition,
      behavior: "smooth",
    });
  }
  
  scrollToMarv() {
    const screenWidth = window.innerWidth;
    const scrollPosition = screenWidth >= 768 ? 1400 : 2600;
    window.scrollTo({
      top: scrollPosition,
      behavior: "smooth",
    });
  }

  tabOneSelected: boolean = true;
  tabTwoSelected: boolean = false; 

  onTabSelected(type: string) {
    if (type === 'marveler') {
      this.tabOneSelected = true;
      this.tabTwoSelected = false;
    } else {
      this.tabOneSelected = false;
      this.tabTwoSelected = true;
    }
  }
}
